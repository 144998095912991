import React, { useState, useEffect } from "react";
import { DatePicker, Row, Col, Form, Select, Input, Button } from "antd";
const { RangePicker } = DatePicker;
const { Option } = Select;

const SegementDemographic = (props) => {
  const [createObject, setCreateObject] = useState([]);
  const [objectNumber, setObjectNumber] = useState(1);

  useEffect(() => {
    setCreateObject([
      ...createObject,
      {
        id: `${props.id}_${objectNumber}`,
        name: `demo_${props.id}_${objectNumber}`,
        attr: "contact_id",
        filter: "eq",
        value: "",
      },
    ]);
    setObjectNumber(objectNumber + 1);
    console.log(`aaa : ${props.id}_${objectNumber}`);
  }, []);

  const attrs = [
    { key: "contact_id", name: "Contact ID" },
    { key: "tags", name: "Tags" },
    { key: "customer_id", name: "Customer ID" },
    { key: "email", name: "Email" },
    { key: "sms", name: "SMS" },
    { key: "facebook", name: "Facebook" },
    { key: "line", name: "Line" },
    { key: "birthdate", name: "Birthdate" },
    { key: "gender", name: "Gender" },
    { key: "member_card_no", name: "Member card no" },
    { key: "member_class", name: "Member class" },
    { key: "point", name: "Point" },
    { key: "register_channel", name: "Register channel" },
    { key: "register_date", name: "Register date" },
    { key: "firstname", name: "Firstname" },
    { key: "lastname", name: "Lastname" },
    { key: "uuid", name: "Uuid" },
  ];
  const filters = [
    { key: "eq", name: "=", value: "" },
    { key: "lt", name: "<", value: "" },
    { key: "gt", name: ">", value: "" },
    { key: "gte", name: ">=", value: "" },
    { key: "nte", name: "<=", value: "" },
    { key: "neq", name: "Not equal", value: "" },
    { key: "eq_date", name: "=(date)", value: <DatePicker /> },
    { key: "lt_date", name: "<(date)", value: <DatePicker /> },
    { key: "gt_date", name: ">(date)", value: <DatePicker /> },
    { key: "gte_date", name: ">=(date)", value: <DatePicker /> },
    { key: "nte_date", name: "<=(date)", value: <DatePicker /> },
    { key: "neq_date", name: "!=(date)", value: <DatePicker /> },
  ];
  const onChangeFilter = (e) => {
    let idLen = e.key.split("_").length;
    let id =
      parseInt(e.key.split("_")[idLen - 2]) + "_" + parseInt(e.key.split("_")[idLen - 1]);
    let val = e.value;
    console.log(e.key.split("_").length);
    console.log(`id`, id, `val`, val, `key`, e.key);
    setCreateObject(
      createObject.map((item) => {
        console.log("item id :", item.id);
        if (item.id === id) {
          return { ...item, filter: val, value: val };
        }
        return item;
      })
    );
  };
  const createRule = () => {
    let number = objectNumber + 1;
    setObjectNumber(number);
    setCreateObject([
      ...createObject,
      {
        id: props.id + "_" + number,
        name: "demo_" + props.id + "_" + number,
        attr: "contact_id",
        filter: "eq",
        value: "",
      },
    ]);
  };
  const deleteField = (id) => {
    setCreateObject(createObject.filter((item) => item.id !== id));
  };
  const field = ({
    id: id,
    name: name,
    attr: attr,
    filter: filter,
    value: value,
  }) => {
    const _filter = filters.find((e) => {
      return e.key === filter;
    });
    console.log(attr);
    return (
      <Row>
        <Col span={8}>
          <Form.Item name={`attr_` + name} initialValue={attr}>
            <Select labelInValue defaultValue={{ value: attr }}>
              {attrs.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={`filter_` + name} initialValue={filter}>
            <Select
              id={`filter_` + name}
              labelInValue
              defaultValue={{ value: filter }}
              onChange={onChangeFilter}
            >
              {filters.map((f) => (
                <Option key={`${f.key}_${id}`} value={f.key}>
                  {f.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={`value_` + name} initialValue={value}>
            {filter.indexOf("_date") > -1 ? <DatePicker /> : <Input />}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Button type="primary" danger onClick={() => deleteField(id)}>
            Delete
          </Button>
        </Col>
      </Row>
    );
  };
  return (
    <>
      {createObject.map((items) =>
        field({
          id: items.id,
          name: items.name,
          attr: items.attr,
          filter: items.filter,
          value: items.value,
        })
      )}
      <Row>
        <Col span={24}>
          <Form.Item
            name={"filter_type_" + props.id}
            defaultValue={"demo"}
            initialValue={"demo"}
          >
            <Input type="hidden" value={"date"} />
          </Form.Item>
          <Button
            id="addRule"
            name="addRule"
            type="primary"
            block
            onClick={createRule}
          >
            OR
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default SegementDemographic;
