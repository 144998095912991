/*!
=========================================================
* Muse Ant Design Dashboard - v1.0.0
=========================================================
* Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/Home";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import { SignOut } from "./pages/SignOut";
import Consent from "./pages/Consent";
import Policy from "./pages/Policy";
import Estamp from "./pages/Estamp";
import JoyliCoin from "./pages/JoyliCoin";
import Products from "./pages/product/Products";
import Product from "./pages/product/Product";
import Categories from "./pages/product/Categories";
import Category from "./pages/product/Category";
import Customer from "./pages/Customer";
import CustomerDetail from "./pages/CustomerDetail";
import CustomerHistory from "./pages/CustomerHistory";
import Users from "./pages/user/users";
import User from "./pages/user/User";
import Main from "./components/layout/Main";
import Default from "./pages/default";
import Roles from "./pages/user/Roles";
import Role from "./pages/user/Role";
import Promotions from "./pages/product/Promotions";
import Promotion from "./pages/product/Promotion";
import Redeem from "./pages/report/Redeem";
import Redemption from "./pages/report/Redemption";
import LineBroadcast from "./pages/crm/line-broadcast";
import LineDashBoard from "./pages/crm/LineDashboard";
import Campaign from "./pages/Campaign";
import CampaignDetail from "./pages/CampaignDetail";
import Segment from "./pages/Segment";
import SegmentDetail from "./pages/SegmentDetail";
import "antd/dist/reset.css";
// import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import "./assets/styles/index.css";
import { Middleware } from "./services/middleware";
import SegmentTest from "./pages/SegmentTest";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} />
        <Route path="/sign-out" exact component={SignOut} />

        <Main>
          <Route exact path="/report" component={Redeem} />
          <Route exact path="/redemption" component={Redemption} />
          <Route exact path="/profile" component={Profile} />
          <Route
            exact
            path="/consent"
            render={() => (
              <Middleware>
                <Consent />
              </Middleware>
            )}
          />
          <Route
            exact
            path="/policy"
            render={() => (
              <Middleware>
                <Policy />
              </Middleware>
            )}
          />
          <Route
            exact
            path="/estamp"
            render={() => (
              <Middleware>
                <Estamp />
              </Middleware>
            )}
          />
          <Route
            exact
            path="/joylicoin"
            render={() => (
              <Middleware>
                <JoyliCoin />
              </Middleware>
            )}
          />
          <Route
            exact
            path="/products"
            render={() => (
              <Middleware>
                <Products />
              </Middleware>
            )}
          />
          <Route exact path="/product/:productId" component={Product} />
          <Route
            exact
            path="/categories"
            render={() => (
              <Middleware>
                <Categories />
              </Middleware>
            )}
          />
          <Route exact path="/category/:cateId" component={Category} />
          <Route
            exact
            path="/customer"
            render={() => (
              <Middleware>
                <Customer />
              </Middleware>
            )}
          />
          <Route
            exact
            path="/line-broadcast"
            render={() => (
              <Middleware>
                <LineBroadcast />
              </Middleware>
            )}
          />
          <Route
            exact
            path="/line-dashboard"
            render={() => (
              <Middleware>
                <LineDashBoard />
              </Middleware>
            )}
          />
          <Route
            exact
            path="/user"
            render={() => (
              <Middleware>
                <Users />
              </Middleware>
            )}
          />
          <Route exact path="/user/:userId" component={User} />
          <Route
            exact
            path="/roles"
            component={Roles}
            render={() => (
              <Middleware>
                <Roles />
              </Middleware>
            )}
          />
          <Route exact path="/role/:roleId" component={Role} />
          <Route
            path="/promotions"
            exact
            render={() => (
              <Middleware>
                <Promotions />
              </Middleware>
            )}
          />
          <Route exact path="/promotion/:promotionId" component={Promotion} />

          <Route
            exact
            path="/customer/:customerId"
            component={CustomerDetail}
          />
          <Route
            exact
            path="/customer/history/:customerId"
            component={CustomerHistory}
          />
          <Route exact path="/campaign" render={() => <Campaign />} />
          <Route
            exact
            path="/campaign/:campaignId"
            component={CampaignDetail}
          />
          <Route
            exact
            path="/segmentation"
            render={() => (
              <Segment />
              // <SegmentTest />
            )}
          />
          <Route exact path="/segment/:segmentId" component={SegmentTest} />
          {/* <Route exact path="/segmenttest" render={() => <SegmentTest />} /> */}
          <Route exact path="/" render={() => <Default />} />
          <Redirect from="*" to="/" />
        </Main>
      </Switch>
    </div>
  );
}

export default App;
