import React, { useState, useEffect } from "react";
import { DatePicker, Row, Col, Form, Select, Input, Button } from "antd";
import { Dayjs } from "dayjs";
const { RangePicker } = DatePicker;
const { Option } = Select;
const SegementMeet = (props) => {
  const [createObject, setCreateObject] = useState([]);
  const [objectNumber, setObjectNumber] = useState(1);

  useEffect(() => {
    setCreateObject([
      ...createObject,
      {
        id: `${props.id}_${objectNumber}`,
        name: `meet_${props.id}_${objectNumber}`,
        attr: "allow_consent",
        filter: "dd",
        value: 1,
      },
    ]);
    setObjectNumber(objectNumber + 1);
    console.log(`aaa : ${props.id}_${objectNumber}`);
  }, []);

  const attrs = [
    { key: "allow_consent", name: "allow_consent" },
    { key: "assign_voucher", name: "assign_voucher" },
    { key: "ecom_forgot_otp_mobile", name: "ecom_forgot_otp_mobile" },
    { key: "ecom_login_success", name: "ecom_login_success" },
    { key: "ecom_request_otp_mobile", name: "ecom_request_otp_mobile" },
    { key: "ecom_update_member", name: "ecom_update_member" },
    { key: "follow-line", name: "Follow-line" },
    { key: "imprt_tracker", name: "imprt_tracker" },
    { key: "line_open", name: "line_open" },
    { key: "line_push_delivered", name: "line_push_delivered" },
    { key: "line_push_delivery_fail", name: "line_push_delivery_fail" },
    { key: "link_click", name: "link_click" },
    { key: "login", name: "login" },
    { key: "logout", name: "logout" },
    { key: "machine_play", name: "machine_play" },
    { key: "point", name: "Point" },
    { key: "purchase_order", name: "purchase_order" },
    { key: "purchase_order_item", name: "purchase_order_item" },
    { key: "register", name: "Register" },
    { key: "sms_delivered", name: "sms_delivered" },
    { key: "sms_delivery_fail", name: "sms_delivery_fail" },
    { key: "unfollow-line", name: "Unfollow-line" },
    { key: "update_point", name: "update_point" },
    { key: "use_voucher", name: "use_voucher" },
  ];
  const filters = [
    {
      key: "dd",
      name: "days",
      value: Array.from({ length: 31 - 1 + 1 }, (_, i) => 1 + i),
    },
    {
      key: "mm",
      name: "month",
      value: Array.from({ length: 12 - 1 + 1 }, (_, i) => 1 + i),
    },
    { key: "between", name: "between", value: <RangePicker /> },
  ];
  const onChangeFilter = (e) => {
    let idLen = e.key.split("_").length;
    let id =
      parseInt(e.key.split("_")[idLen - 2]) +
      "_" +
      parseInt(e.key.split("_")[idLen - 1]);
    let val = e.value;
    console.log(e.key.split("_").length);
    console.log(`id`, id, `val`, val, `key`, e.key);
    setCreateObject(
      createObject.map((item) => {
        console.log("item id :", item.id);
        if (item.id === id) {
          return {
            ...item,
              filter: val,
              value: val === "yy" ? 2000 : 1
          };
        }
        return item;
      })
    );
  };
  const createRule = () => {
    let number = objectNumber + 1;
    setObjectNumber(number);
    setCreateObject([
      ...createObject,
      {
        id: props.id + "_" + number,
        name: "meet_" + props.id + "_" + number,
        attr: "allow_consent",
        filter: "dd",
        value: 1,
      },
    ]);
  };
  const deleteField = (id) => {
    setCreateObject(createObject.filter((item) => item.id !== id));
  };
  const field = ({
    id: id,
    name: name,
    attr: attr,
    filter: filter,
    value: value,
  }) => {
    const _filter = filters.find((e) => {
      return e.key === filter;
    });
    console.log(attr);
    return (
      <Row>
        <Col span={8}>
          <Form.Item name={`attr_` + name} initialValue={attr}>
            <Select labelInValue defaultValue={{ value: attr }}>
              {attrs.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={`filter_` + name} initialValue={filter}>
            <Select
              id={`filter_` + name}
              labelInValue
              defaultValue={{ value: filter }}
              onChange={onChangeFilter}
            >
              {filters.map((f) => (
                <Option key={`${f.key}_${id}`} value={f.key}>
                  {f.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>          
          <Form.Item name={`value_` + name} initialValue={value}>
            {filter === "between" ? (
              <RangePicker />
            ) : (
              <Select labelInValue defaultValue={value}>
                {_filter.value.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Button type="primary" danger onClick={() => deleteField(id)}>
            Delete
          </Button>
        </Col>
      </Row>
    );
  };
  return (
    <>
      {createObject.map((items) =>
        field({
          id: items.id,
          name: items.name,
          attr: items.attr,
          filter: items.filter,
          value: items.value,
        })
      )}
      <Row>
        <Col span={24}>
          <Form.Item
            name={"filter_type_"+props.id}
            defaultValue={"meet"}
            initialValue={"meet"}
          >
            <Input type="hidden" value={"date"} />
          </Form.Item>
          <Button
            id="addRule"
            name="addRule"
            type="primary"
            block
            onClick={createRule}
          >
            OR
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default SegementMeet;
