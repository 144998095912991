import React, { useState, useRef, useEffect } from "react";
import { useParams, Redirect, useHistory } from "react-router-dom/";

import {
  Modal,
  Button,
  Row,
  Col,
  Card,
  Form,
  Input,
  Switch,
  Skeleton,
  DatePicker,
  Flex,
  Space,
} from "antd";
import {
  BorderlessTableOutlined,
  BorderOutlined,
  DeleteOutlined,
  ShakeOutlined,
} from "@ant-design/icons";
import SegementDate from "../components/segment/date.component";
import SegementDemographic from "../components/segment/demographic.component";
import SegementEngaged from "../components/segment/engaged.component";
import SegementMeet from "../components/segment/meet.component";
import conditionTypes from "../components/segment/filter.data";
import { axios_json } from "../axios";
import responseHandleSrv from "../services/responseHandleSrv";
import { authHeader } from "../services/auth-header";

const SegmentTest = () => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [loadings, setLoadings] = useState(false);
  const [preload, setPreload] = useState(false);
  const [open, setOpen] = useState(false);
  const [objectList, setObjectList] = useState([]);
  const [objKey, setObjKey] = useState(0);
  const history = useHistory();

  const createObject = (filterType) => {
    setObjectList([...objectList, { filterType: filterType, index: objKey }]);
    setObjKey(objKey + 1);
    setOpen(false);
    console.log(objectList);
  };
  const removeObject = (id) => {
    // objectList.push(objectList.splice(objectList.indexOf(id), 1)[0]);
    // setObjectList([...objectList]);
    // console.log(objectList);
    setObjectList([...objectList]);
    setObjectList(objectList.filter((item) => item.index !== id));
  };

  const getTypeTitle = (type) => {
    return conditionTypes.find((item) => item.type === type).title;
  };

  const submit = async (values) => {
    console.log("=== submit ===");
    console.log(values);
    let data = [];
    Object.keys(values).map((key, index) => {
      if (key.indexOf("filter_type_") !== -1) {
        data.push({
          id: parseInt(key.split("_")[key.split("_").length - 1]),
          type: values[key],
          condition: [],
        });
      }
    });
    data.map((item, index) => {
      let attrs = [];
      let operators = [];
      let vals = [];
      let conditions = [];
      Object.keys(values).map((key2, index) => {
        if (key2.indexOf(`attr_${item.type}_${item.id}_`) !== -1) {
          attrs.push(values[key2]);
        }
        if (key2.indexOf(`filter_${item.type}_${item.id}_`) !== -1) {
          operators.push(values[key2]);
        }
        if (key2.indexOf(`value_${item.type}_${item.id}_`) !== -1) {
          vals.push(values[key2]);
        }
      });
      for (let i = 0; i < attrs.length; i++) {
        conditions.push({
          attr: attrs[i].value ?? attrs[i],
          operator: operators[i].value ?? operators[i],
          value: vals[i].value ?? vals[i],
        });
      }
      data[index] = { ...item, condition: conditions };
    });
    let payload = {
      name: values.name,
      alias: values.alias,
      description: values.description,
      refresh: values.refresh === true ? 1 : 0,
      is_active: values.active === true ? 1 : 0,
      conditions: data,
    }
    // console.log(JSON.stringify(payload));
    addNew(payload);
  };
  const addNew = async (payload) => {
    setLoadings(true);
    (async () => {
      await axios_json
        .post(`/api/segment/create`, payload, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data.success) {
            responseHandleSrv.handleSuccess(response);
          }
          setLoadings(false);
        })
        .catch((err) => {
          setLoadings(false);
          responseHandleSrv.handleError(err);
        })
        .finally(() => {
          history.push("/segmentation");
        });
    })();
  };

  return (
    <>
      <Row xs={24} xl={24}>
        <Col xs={24} xl={24}>
          <Card
            title="Segment"
            extra={[
              <Button
                type="primary"
                loading={loadings}
                form="frmsegment"
                htmlType="submit"
              >
                Save
              </Button>,
            ]}
          >
            <div>
              {preload ? (
                <Skeleton active />
              ) : (
                <>
                  <Form
                    {...{ labelCol: { span: 4 }, wrapperCol: { span: 20 } }}
                    form={form}
                    name="frmsegment"
                    onFinish={submit}
                    onFinishFailed={() => console.log("Failed")}
                  >
                    <Row justify="center" gutter={[0, 10]}>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="name"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                Name
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "fill name",
                              },
                            ]}
                          >
                            <Input placeholder="Name" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="alias"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                alias
                              </label>
                            }
                            rules={[
                              {
                                required: true,
                                message: "please fill alias",
                              },
                            ]}
                          >
                            <Input placeholder="alias" />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="description"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                description
                              </label>
                            }
                            rules={[
                              {
                                required: false,
                                message: "description",
                              },
                            ]}
                          >
                            <Input.TextArea rows={6} />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="active"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                active
                              </label>
                            }
                            valuePropName="checked"
                            initialValue={true}
                          >
                            <Switch defaultChecked />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={22}>
                        <div>
                          <Form.Item
                            name="refresh"
                            label={
                              <label
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                refresh
                              </label>
                            }
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Switch />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={22}>
                        {objectList &&
                          objectList.map((item) => (
                            <>
                              <Card
                                title="Segment Conditions"
                                style={{ margin: "20px 0 0 0" }}
                              >
                                <Flex justify="space-between">
                                  <h3>{getTypeTitle(item.filterType)}</h3>
                                  <Button
                                    danger
                                    onClick={() => removeObject(item.index)}
                                    style={{
                                      border: "none",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </Flex>
                                {item.filterType === "date" && (
                                  <SegementDate
                                    key={item.index}
                                    id={item.index}
                                  />
                                )}
                                {item.filterType === "demographic" && (
                                  <SegementDemographic
                                    key={item.index}
                                    id={item.index}
                                  />
                                )}
                                {item.filterType === "engagedWithCampaign" && (
                                  <SegementEngaged
                                    key={item.index}
                                    id={item.index}
                                  />
                                )}
                                {item.filterType === "meetEvents" && (
                                  <SegementMeet
                                    key={item.index}
                                    id={item.index}
                                  />
                                )}
                              </Card>
                            </>
                          ))}
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={22}>
                        <Card>
                          <Button
                            type="dashed"
                            block
                            onClick={() => setOpen(true)}
                          >
                            Add Segement Conditions
                          </Button>
                        </Card>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </div>
          </Card>
        </Col>
      </Row>
      {/* <Button onClick={() => setOpen(true)}>TEST</Button> */}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
      >
        <Row gutter={[16, 24]}>
          {conditionTypes.map((item, _) => (
            <>
              <Col span={12}>
                <Card hoverable onClick={() => createObject(item.type)}>
                  <Flex vertical>
                    <h2>{item.title}</h2>
                    <p style={{ color: "#666" }}>{item.description}</p>
                  </Flex>
                </Card>
              </Col>
            </>
          ))}
        </Row>
      </Modal>
    </>
  );
};
export default SegmentTest;
