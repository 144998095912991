import React, { useState, useEffect } from "react";
import { DatePicker, Row, Col, Form, Select, Input, Button } from "antd";
const { RangePicker } = DatePicker;
const { Option } = Select;

const SegementDate = (props) => {
  const [createObject, setCreateObject] = useState([]);
  const [objectNumber, setObjectNumber] = useState(1);

  useEffect(() => {
    setCreateObject([
      ...createObject,
      {
        id: `${props.id}_${objectNumber}`,
        name: `date_${props.id}_${objectNumber}`,
        attr: "birthdate",
        filter: "dd",
        value: 1,
      },
    ]);
    setObjectNumber(objectNumber + 1);
    console.log(`aaa : ${props.id}_${objectNumber}`);
  }, []);

  const attrs = [
    { key: "birthdate", name: "birthdate" },
    { key: "create_date", name: "register date" },
  ];
  const filters = [
    {
      key: "dd",
      name: "day",
      value: Array.from({ length: 31 - 1 + 1 }, (_, i) => 1 + i),
    },
    {
      key: "mm",
      name: "month",
      value: Array.from({ length: 12 - 1 + 1 }, (_, i) => 1 + i),
    },
    {
      key: "yy",
      name: "year",
      value: Array.from({ length: 2024 - 1970 + 1 }, (_, i) => 1970 + i),
    },
    { key: "between", name: "between", value: <RangePicker /> },
  ];
  const onChangeFilter = (e) => {
    let id =
      parseInt(e.key.split("_")[1]) + "_" + parseInt(e.key.split("_")[2]);
    let val = e.value;
    console.log(`id`, id, `val`, val, `key`, e.key);
    setCreateObject(
      createObject.map((item) => {
        if (item.id === id) {
          return { ...item, filter: val, value: val === "yy" ? 2000 : 1 };
        }
        return item;
      })
    );
  };
  const createRule = () => {
    let number = objectNumber + 1;
    setObjectNumber(number);
    setCreateObject([
      ...createObject,
      {
        id: props.id + "_" + number,
        name: "date_" + props.id + "_" + number,
        attr: "birthdate",
        filter: "dd",
        value: 1,
      },
    ]);
  };
  const deleteField = (id) => {
    setCreateObject(createObject.filter((item) => item.id !== id));
  };
  const field = ({
    id: id,
    name: name,
    attr: attr,
    filter: filter,
    value: value,
  }) => {
    const _filter = filters.find((e) => {
      return e.key === filter;
    });
    console.log(attr);
    return (
      <Row>
        <Col span={8}>
          <Form.Item name={`attr_` + name} initialValue={attr}>
            <Select labelInValue defaultValue={{ value: attr }}>
              {attrs.map((item) => (
                <Option key={item.key} value={item.key}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name={`filter_` + name} initialValue={filter}>
            <Select
              id={`filter_` + name}
              labelInValue
              defaultValue={{ value: filter }}
              onChange={onChangeFilter}
            >
              {filters.map((f) => (
                <Option key={`${f.key}_${id}`} value={f.key}>
                  {f.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name={`value_` + name} initialValue={value}>
            {filter === "between" ? (
              <RangePicker />
            ) : (
              <Select labelInValue defaultValue={value}>
                {_filter.value.map((value) => (
                  <Option key={value} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={2}>
          <Button type="primary" danger onClick={() => deleteField(id)}>
            Delete
          </Button>
        </Col>
      </Row>
    );
  };
  return (
    <>
      {createObject.map((items) =>
        field({
          id: items.id,
          name: items.name,
          attr: items.attr,
          filter: items.filter,
          value: items.value,
        })
      )}
      <Row>
        <Col span={24}>
          <Form.Item
            name={"filter_type_" + props.id}
            defaultValue={"date"}
            initialValue={"date"}
          >
            <Input type="hidden" value={"date"} />
          </Form.Item>
          <Button
            id="addRule"
            name="addRule"
            type="primary"
            block
            onClick={createRule}
          >
            OR
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default SegementDate;
